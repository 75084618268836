 <template >
    
<div id="e404">
    <div class="head-404">
        <a href="/">
            <img src="../assets/logo-p.png" alt="logo" class="logo-png">
        </a>
    </div>
    <div class="body-404">
        <img src="../assets/oops-mo.png" alt="oops" class="oops mo">
        <img src="../assets/oops-pc.png" alt="oops" class="oops pc">
        <h1>We cant find the page you're looking for. </h1>      
        <a href="/" class="btn-arrow">BACK TO HOME <img src="../assets/arrow.svg" alt="arrow"></a>  
    </div>
</div>
</template>
<script>
export default {
    name:'Error404',
    data(){
        return {
            status:'error',
            message:''
        }
    }
}
</script>
<style>
#e404 .logo-png {
    width: 43.720vw;
    height: 15.217vw;
}
#e404 .head-404{
   padding: 14.019vw;
   position: absolute;
}
#e404 .oops {
    width: 81.542vw;
    height: 52.570vw;
    margin: auto;
}
#e404 .oops.mo {
    display: flex;
}
#e404 .oops.pc {
    display: none;
}
#e404 .body-404{
    padding: 43.692vw 7.710vw;
}
#e404 h1 {
    font-family: 'MADE Outer Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 6.542vw;
    line-height: 8.178vw;
    color: #262FFD;
    margin: 8.879vw 0;
    width: 53vw;
}
#e404 .btn-arrow {
    font-family: 'MADE Outer Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 3.037vw;
    line-height: 3.738vw;
    text-align: center;
    text-transform: uppercase;
    color: #262FFD;
    text-decoration-color: transparent;
    display: flex;
    align-items: center;
    margin-top: 21.729vw;
}
#e404 .btn-arrow img {
    width: 13.318vw;
    padding-left: 4vw;
}
@media (min-width: 1040px) {
#e404 .head-404 {
    padding: 3.125vw 4.531vw;
} 
#e404 .logo-png {
    width: 9.427vw;
    height: 3.281vw;
}
#e404 .oops.mo {
    display: none;
}
#e404 .oops.pc {
    display: flex;
} 
#e404 .body-404 {
    padding: 9.063vw 23.49vw;
    width: 52vw;
    display: flex;
    flex-wrap: wrap;
}
#e404 .oops {
    width: 42.031vw;
    height: 27.24vw;
    margin: 0;
}#e404 h1 {
    position: absolute;
    margin: 15.313vw 0 0 12.031vw;
    font-size: 3.125vw;
    line-height: 3.958vw;
    width: 40vw;
}
#e404 .btn-arrow {
    margin-top: 1.875vw;
    font-size: 0.677vw;
    line-height: 0.833vw;
    margin-left: 39vw;
}#e404 .btn-arrow img {
    width: 2.969vw;
    padding-left: 1vw;
}
}
</style>
